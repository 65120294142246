// Vendor scripts

// import '../node_modules/popper.js/dist/popper';
// import '../node_modules/bootstrap/dist/js/bootstrap';
// import '../node_modules/slick-carousel/slick/slick';
// Starter scripts
import './scripts/main';

// Custom scripts
// import './scripts/flipcard';
// import './scripts/swiper';