exports.init = ($, utils) => {
  'use strict';
  //empty

  let browse = {
    debug: true,
    query: false,
    action:$('.view-filters form').attr('action'),
    containsObject:(obj, list)=>{
      let i;
      let found = false;
      for (i = 0; i < list.length; i++) {
        
        if (list[i].text == obj.text) {
          found = true;     
        }
        // console.log(found, 'test-iterate',i ); //always false
      }
      // console.log(found, 'test', list ); //always false
      return found;
  
    },
    init: ()=>{
      if (browse.debug) {
        console.log('browse.init()');
      }

      try {
       
        if ($('.view-empty').length) {
          
          $('#block-system-main .view-filters').hide();
          $('#block-system-main .view-header').hide();
        }
        if ($('.slider-component').length) {
        }
        //set up counts
        if ($('.total').length) {
          
          // $('.count').text($('.count').text().replace('%count%', $('.total').text()));
        }
        
        let options = [];
        $('#edit-items-per-page option').each((i,ele)=>{
          //$('.per-page').replace('%count%', $('.total').text());
          options
            .push('<a class="items-per-page-option" href="'+browse.action+'" data-carry="'+$(ele).val()+'"> '+ $(ele).val() +'</a>');

        });

        if (options.length > 0) {
          
          $('.per-page').html($('.per-page').text().replace('%per-page%',options.toString()));
        }

        // $('#edit-items-per-page').change(function(e){
        //   let data = $(e.target).val();
        //   $(e.target).val(data);
        //   console.log('data', data);
        //   $('form').trigger('change');
        // });
        
        $('.items-per-page-option').on('click', (e)=>{
          e.preventDefault();
          let dataTarget = $(e.target).attr('data-carry');
          
          browse.facetHandler(dataTarget, 'items_per_page');
          // $('#edit-items-per-page')
          // .val(dataTarget)
          // .trigger('change')
          // .trigger('click');   
          
        });

        $('.view-browse-by-bisac-code .sort-by-container .dropdown-menu').on('click',(e)=>{
          e.preventDefault();
        });

          // $('#edit-sort-by option').each((i,ele)=>{
          //   $('.sort-by-container .dropdown-menu').append('<a class="dropdown-item asc items-sort-by-option" href="'+browse.action+'" data-key="sort_by" data-sort-order="ASC" data-carry="'+$(ele).val()+'"> '+ $(ele).text() +' (ASC)</a>')
          //   $('.sort-by-container .dropdown-menu').append('<a class="dropdown-item desc items-sort-by-option" href="'+browse.action+'" data-key="sort_by" data-sort-order="DESC" data-carry="'+$(ele).val()+'"> '+ $(ele).text() +' (DESC)</a>')
          // });
          
          // setTimeout(() => {
            
          //   $('.items-sort-by-option').each((n,i)=>{
          //     // console.log(i,n,'sort_by');  
          //     $(i).on('click',(e)=>{
          //         e.preventDefault();
          //         let item = $(e.target);
          //         // console.log(item.attr('data-carry'),'sort_by');
          //         browse.facetHandler(item.attr('data-carry'),'sort_by', item.attr('data-sort-order'));

          //       });
          //     });
          // }, 900);


          //set active on page
          if (
            utils.getQueryString('items_per_page') ||
            utils.getQueryString('sort_order') ||
            utils.getQueryString('page') //page is default

          ) {
            setTimeout(() => {
              let pageN = utils.getQueryString('items_per_page');
              let pageSort = $(`[data-carry="${utils.getQueryString('sort_by')}"]`).first().text();
              let pageOrder = utils.getQueryString('sort_order');
              
              $('.per-page [data-carry="'+ pageN +'"]').addClass('active');
              $('.sort-by-container > button').first().text(`${pageSort.substr(0, pageSort.indexOf('('))} (${pageOrder})`);

              $('.views-reset-button').appendTo('.float-right.sort');

              $('.views-reset-button').on('click',(e)=>{
                e.preventDefault();

                window.location.href = window.location.href.substr(0,window.location.href.indexOf('?'));
              });
            }, 400);
          }

          //term page
          if($('.term-list-template').length){

            if ($('.term-list-template .lists').length) {
              // $('.hero-banner').after('<div class="sub-menu"><ul class="menu"></ul></div>');
              
              let ioff = 0;
              $('.view-grouping-header a').remove().each((x3,i)=>{
                if ($(i).text().length == 0) {
                  
                }else{
                  // console.log('$(i)',$(i));
                  
                  // $('.sub-menu ul').append(`<li class="leaf"></li>`);
                  // $(i).attr('href','/lists'+$(i).attr('href'));
                  // $(i).appendTo($('.sub-menu ul li').eq(ioff));
                  ioff = ioff + 1;
                }
                
              });
  
              //get rid of titles from grouping
              $('.view-grouping-content h3').remove();
              // browse.listControl($('.view-grouping-content .row .col'));
            }

                                              ///////                            ////// TERM/CATEGORY

            if ($('.term-list-template .category').length) {
              let termsList = [];
              
            //   $('.view-content').prepend('<aside class="col col-3"></aside>');
            //   $('.views-field-term-node-tid a').each((i,v)=>{
                
            //     if (browse.containsObject({link:$(v).attr('href'),text:$(v).text()},termsList)) {
                  
            //     }else{
            //       termsList.push({link:$(v).attr('href'),text:$(v).text()});
            //     }
            //   });
            //   //sort term list
            //   termsList = termsList.sort(function (a, b) {
            //     return a.text.localeCompare( b.text );
            // });
            //   // console.log('term list', termsList);
            //   for (let index = 0; index < termsList.length; index++) {
            //     const element = termsList[index];
                
            //     $('.view-taxonomy-term aside').append(`<a href="${element.link}">${element.text}</a>`);
            //   }

            //   //wrap view-groupings
            //   $( ".view-grouping" )
            //     .wrapAll( "<div class='col col-9 row' />");
            //   $( ".view-grouping " )
            //     .addClass( "col col-3");

              $('.view-taxonomy-term > .view-content').addClass('row');
              $('.views-field-term-node-tid a').remove();
              $('.view-grouping-header').remove();
              $('.view-taxonomy-term > .view-content h3').remove();
              $('.view-taxonomy-term > .view-content .col.views-column-1').removeClass('col-lg-3 col-sm-2');
              $('.view-grouping-content').unwrap();
            //     let tmpChar = "";
            //     $('aside.col.col-3 a').each((elem, index)=>{
            //       if (tmpChar != "") {
            //         if ($(index).text().charAt(0) != tmpChar) {
            //           tmpChar = $(index).text().charAt(0);
                      
            //           $(index).prepend('<br><span>'+ tmpChar + '</span><hr>')
            //         }
            //       }else{
                    
            //         if ($(index).text().charAt(0) != tmpChar) {
            //           tmpChar = $(index).text().charAt(0);
                      
            //           $(index).prepend('<span>'+ tmpChar + '</span><hr>')
            //         }
            //       }
            //     });

            //     //remove alt products
                $('.views-column-1').siblings().remove();

            //     browse.listControl($('.view-grouping.col.col-3'), true);
            //     $('.list-in').remove
                ///////                             ////////////////////////      END TERM
            } else{
              
              browse.listControl($('.view-grouping-content .row .col'));
            }
            
            
          }

          if ($('.browse-template #block-views-bestsellers-component-block').length) {
            $('#block-system-main .view-header form').first().prependTo($('.browse-template #block-views-bestsellers-component-block'));
            $('.browse-template #block-views-bestsellers-component-block .view-header').show();
          }

      } catch (err) {
        if (browse.debug) {
          console.log(err);
        }

        $('body').addClass('loaded');
      }
    },
    listControl:($books, categories)=>{
      
      let options = ["12","24","48", "56"];
      let indexes = 0;
      let save = $('.pagination .page-item').eq(2);
      let page = (window.location.href.split('?')[1])?parseInt(window.location.href.split('?')[1].replace( /^\D+/g, '')):'';
      
      save.detach();

      let settings = {
        perPage:12,
        index:page
      }

      //set up pagination
      indexes = Math.floor( $books.length/settings.perPage);
      // $('.pagination .page-item').eq(1).remove();

      // console.log('indexes',page);
      // for (let index = 0; index < indexes; index++) {
      
      //   $('.pagination').append(`<li class="page-item"><a class="page-link" href="${window.location.href.split('?')[0]}?page=${index + 1}">${(index + 1)}</a></li>`);
      // }
      // $('.pagination').append(save);
      // $('.count').text($('.count').text().replace('%count%', $books.length));
      
      // $('.per-page').html($('.per-page').text().replace('%per-page%',options.toString()));
      
      if (!categories) {
        
        $('.view-grouping-content').remove();
      }else{
        $('.views-fieldviews-field-term-node-tid').remove();
      }
      
      $('.view-taxonomy-term .view-content').append(`<div class="container"><div class="row list-in"></div></div>`);
      
      // if (settings.index != '') {
      //   $books = $books.splice((settings.perPage * settings.index));
      //   console.log(settings, $books,'books setting');
      // }
      console.log('$books',$books)
      for (let index = 0; index < 24; index++) {
        
        const element = $books[index];
        
        $('.list-in').append(element);
      }

      // console.log($books);
    },
    facetHandler:(name,key,order)=>{
      let updateQueryStringParam = function (key, value) {

        let baseUrl = [location.protocol, '//', location.host, location.pathname].join(''),
          urlQueryString = document.location.search,
          newParam = key + '=' + value,
          params = '?' + newParam;

        // If the "search" string exists, then build params from it
        if (urlQueryString) {
          var updateRegex = new RegExp('([\?&])' + key + '[^&]*');
          var removeRegex = new RegExp('([\?&])' + key + '=[^&;]+[&;]?');

          if (typeof value == 'undefined' || value == null || value == '') { // Remove param if value is empty
            params = urlQueryString.replace(removeRegex, "$1");
            params = params.replace(/[&;]$/, "");

          } else if (urlQueryString.match(updateRegex) !== null) { // If param exists already, update it
            params = urlQueryString.replace(updateRegex, "$1" + newParam);

          } else { // Otherwise, add it to end of query string
            params = urlQueryString + '&' + newParam;
          }
        }

        // no parameter was set so we don't need the question mark
        params = params == '?' ? '' : params;

        window.history.replaceState({}, "", baseUrl + params);
      };
      if (browse.debug) {
        console.log(`browse.facetHandler(${name}&${key})`);
      }

      //check if query exists
      if (utils.getQueryString(key)) {
        updateQueryStringParam(key, name); 
        if (order) {
          updateQueryStringParam('sort_order', order); 
        } 
      }else{
        //do any exist?
        if (browse.query) {
          //if you do not have existing key
          if (browse.query.includes(key)) {
            // browse.query = browse.query.replace('&page=','&page=0').replace(`${key}=`,);
            updateQueryStringParam(key, name);  
            if (order) {
              updateQueryStringParam('sort_order', order); 
            } 
          }else{
            //add new clause
            // browse.query = `${browse.query}&${key}=${name}`;
            updateQueryStringParam(key, name);  
            if (order) {
              updateQueryStringParam('sort_order', order); 
            } 
          }
        }else{
          updateQueryStringParam(key, name); 
          updateQueryStringParam('page', '0'); 
          if (order) {
            updateQueryStringParam('sort_order', order); 
          } 
          //add new clause
          browse.query = `${window.location.href}`;
        }
        
      }
      window.location.href = `${browse.query}`;
    }
  }

  if ($('.browse-template').length) {
    browse.init();
  }

};
