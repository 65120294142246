exports.init = ($) =>{
  'use strict';

  var example = {
    debug: false,
    init: () =>{
      if (this.debug) {
        console.log('example.init()');
      }
      example.bindings();
    },
    bindings: () =>{
      if (this.debug) {
        console.log('example.bindings()');
      }
    }
  };

  if ($('body').length) {
    example.init();
  }
};
