exports.init = ($) =>{
  'use strict';

  const alert = {
    debug: true,
    alertCookie: document.cookie,
    getCookie: (name) =>{
      let re = new RegExp(name + '=([^;]+)');
      let value = re.exec(document.cookie);
      
      return (value != null) ? unescape(value[1]) : null;
    },
    init: () =>{
      if (this.debug) {
      }
      
      alert.bindings();
      const cook = localStorage.getItem('alertBanner');
      console.log(cook, 'alert');
      // console.log('alert.init()',alert.getCookie('alertBanner'),);
      if (cook && cook == $('.alert').data('cache')) {
        //hide the banner as it has been hidden before
        $('.alert').hide();
      }else{
        localStorage.removeItem('alertBanner');
      }

    },
    bindings: () =>{
      if (this.debug) {
        console.log('alert.bindings()');
      }
      $('.alert .close').on('click', ()=>{
        
        localStorage.setItem( 'alertBanner', $('.alert').data('cache'));
        $('.alert').hide('slow');
      });
    }
  };

  if ($('body').length) {
    alert.init();
  }
};
