exports.init = () => {
  'use strict';
    /* Slick needs no get Reinitialized on window Resize after it was destroyed */
    $jq(window).on('load resize orientationchange', function() {
      $jq('.view-our-news-updates .container > div.d-inline-flex').each(function(){
          var $carousel = $jq(this);
          /* Initializes a slick carousel only on mobile screens */
          // slick on mobile
          if ($jq(window).width() > 1020) {
              if ($carousel.hasClass('slick-initialized')) {
                  $carousel.slick('unslick');
              }
          }
          else{
              if (!$carousel.hasClass('slick-initialized')) {
                  $carousel.slick({
                      slidesToShow: 1,
                      slidesToScroll: 1,
                      mobileFirst: true,
                  });
              }
          }
      });
  });


  $jq('.flexslider ul').slick({
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 4000,
    arrows : false,
    dots:true
  });

  $jq('.slider-component').closest('.block-views').addClass('slider-component');
    $jq('.best-sellers-list-slider  .abaproduct-title a, .slider-component .abaproduct-title a, .view-staff-pick-component .abaproduct-title a').each(function (index, element) {
      // element == this  
      var text = $jq(this).text();
      text = text.substr(0,55) + '...';
      $jq(this).text(text);
          
    });



  $jq('.slider-component .view-content').slick({
    slidesToShow: 4,
    slidesToScroll: 1,
    autoplay: false,
    autoplaySpeed: 4000, 
    dots: false,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: false
        }
      },
      {
        breakpoint: 780,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
      // You can unslick at a given breakpoint now by adding:
      // settings: "unslick"
      // instead of a settings object
    ]
  });

  // $jq('.view-bestsellers-component .view-content ').slick({
  //   slidesToShow: 4,
  //   slidesToScroll: 1,
  //   autoplay: false,
  //   autoplaySpeed: 2000, 
  //   responsive: [
  //     {
  //       breakpoint: 1024,
  //       settings: {
  //         slidesToShow: 3,
  //         slidesToScroll: 3,
  //         infinite: true,
  //         dots: true
  //       }
  //     },
  //     {
  //       breakpoint: 780,
  //       settings: {
  //         slidesToShow: 2,
  //         slidesToScroll: 2
  //       }
  //     },
  //     {
  //       breakpoint: 480,
  //       settings: {
  //         slidesToShow: 1,
  //         slidesToScroll: 1
  //       }
  //     }
  //     // You can unslick at a given breakpoint now by adding:
  //     // settings: "unslick"
  //     // instead of a settings object
  //   ]
  // });
  $jq('.slider-component').on('setPosition', function (event, slick) {
    $jq(slick.$slides).height('auto').css('height', $jq(slick.$slideTrack).height() + 'px');
    });
};
