const $jq = require('./vendor/jquery.min.js');
const globals = require('./common/globals.js');

const example = require('./components/example.js');
const alert   = require('./components/alert.js');
const slides   = require('./components/slides.js');
const browse   = require('./components/browse.js');
// const speedbump   = require('./components/speedbump.js');
// const product_compare   = require('./components/product-compare.js');
// console.log($jq);

module.exports = (() => {
  let $ = $jq;
  $(($) => {
   
    let utils = {
      debug: true,
      tallest: 0,
      equalHeights: (ele) =>{
        if (this.debug) {
          console.log('wf.utils.equalHeights();');
        }
        utils.tallest = 0;
        $.each(ele, (index, val) =>{
          console.log('equal', $(val), $(val).outerHeight());
          if ($(val).outerHeight() > utils.tallest)
            utils.tallest = $(val).outerHeight();
          $(val).outerHeight(utils.tallest);
        });

        ele.outerHeight(utils.tallest);
      },
      scrollTo: (target) =>{
        if (this.debug) {
          console.log('wf.utils.ScrollTo');
        }
      },
      ajaxStatus: () =>{
        if (this.debug) {
          console.log('wf.utils.ajaxStatus');
        }
      },
      ajaxStart: (container) =>{
        if (this.debug) {
          console.log('wf.utils.ajaxStart(' + container + ')');
        }
        //let spinner = svgSpinnerOBJ;
        $.ajaxStart((container) =>{
          // animation();
          container.css('opacity', '0.5');
          spinner.show('fast');
        });
        $.ajaxStop((container) =>{
          // animation();
          container.css('opacity', '1');
          spinner.hide('fast');
        });
      },
      imagesLoaded: () =>{
        if (this.debug) {
          console.log('wf.utils.imagesLoaded');
        }
        // Fn to allow an event to fire after all images are loaded

        // get all the images (excluding those with no src attribute)
        var $imgs = $(document).find('img[src!=""]');
        // if there's no images, just return an already resolved promise
        if (!$imgs.length) {
          return $.Deferred()
            .resolve()
            .promise();
        }

        // for each image, add a deferred object to the array which resolves when the image is loaded (or if loading fails)
        var dfds = [];
        $imgs.each(()=> {
          var dfd = $.Deferred();
          dfds.push(dfd);
          var img = new Image();
          img.onload = ()=> {
            dfd.resolve();
          };
          img.onerror = ()=> {
            dfd.resolve();
          };
          img.src = this.src;
        });

        // return a master promise object which will resolve when all the deferred objects have resolved
        // IE - when all the images are loaded
        return $.when.apply($, dfds);
      },
      getItem: (ID) =>{
        //Leaving for example, for service data
        if (this.debug) {
          console.log('wf.utils.getPromoByID(' + ID + ')');
        }
        // var self = this;
        // var promo;

        // //abstract method of selecting a promo by ID
        // $.getJSON(dataPathPromos, (json, textStatus) =>{
        //   promo = json.promos[ID];
        // });
        // return promo;
      },
      isNear: ($ele, distance, event) =>{
        // for chat share print module
        if (this.debug) {
          //console.log('wf.utils.isNear('+ $ele +' '+ distance +' '+ event +')');
        }

        var left = $ele.offset().left - distance,
          top = $ele.offset().top - distance,
          right = left + $ele.width() + 2 * distance,
          bottom = top + $ele.height() + 2 * distance,
          x = event.pageX,
          y = event.pageY;

        return x > left && x < right && y > top && y < bottom;
      },
      validation: {
        debug: true,
        isNotBlank: (val) =>{
          if (this.debug) {
            console.log('wf.utils.isNotBlank(' + val + ')');
          }
          if (val.length) {
            return true;
          } else {
            return false;
          }
        },
        isMinCharacters: () =>{
          if (this.debug) {
            console.log('wf.utils.isMinCharacters()');
          }
        }
      },
      getQueryString: (name, url) =>{
        if (this.debug) {
          console.log('wf.utils()');
        }
        if (!url) {
          url = window.location.href;
        }
        name = name.replace(/[\[\]]/g, '\\$&');
        var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
          results = regex.exec(url);
        if (!results) return null;
        if (!results[2]) return '';

        return decodeURIComponent(results[2].replace(/\+/g, ' '));
      }
    };
    // end utils

    //are we good?
    if ($('body').length) {
      //utils are just that, global
      //framework such as every page items
      //and animations are kept in globals.js

      //equalheights carousels?
      // utils.equalHeights($('.slider-component '));

      $('#main-menu > ul > li > a').on('click', (e)=>{
        e.preventDefault();
      });

      globals.init($, utils);

      //this is an example invoction of a module that passes the utils obj to the scope
      example.init($, utils);

      //alert
      alert.init($, utils);
      slides.init();
      browse.init($, utils);
      //speedbump
      // speedbump.init($);

      //product compare
      // product_compare.init($, utils);
      $(window).on('load', () =>{
        setTimeout(() =>{
          console.log($,jQuery, 'the problem');
          $('.loader').addClass('loaded');
          $('.loader').hide('slow');
          $('body')
            .addClass('loaded')
            .addClass('animate');
        }, 1500);
      }); //this waits for images to render
      setTimeout(() =>{
        console.log($,jQuery, '404 items');
        $('.loader').addClass('loaded');
        $('.loader').hide('slow');
        $('body')
          .addClass('loaded')
          .addClass('animate');
      }, 2500); /// hit this if stuff fails to load
    }
  });
})();
