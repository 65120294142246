exports.init = ($, utils) =>{
  'use-strict';
  
  let ordo = {
    debug: true,
    tallest: 0,
    init: () =>{
      if (ordo.debug) {
        console.log('ordo.init()');
      }

      try {
        $.ajaxSetup({
          cache: false
        });
        ordo.Products();
        //bind custom scroll fade-in
        ordo.Bindings();

        //if search by author
        if ($('.view-search-by-author').length) {
          
          // $('.view-search-by-author table tbody > tr').unwrap();
          // $('.view-search-by-author table > td').wrap('<tr/>');
        }

        //equalheights bestsellers page
        setTimeout(()=>{
          console.log('heights', $('.view-taxonomy-term .col.col-lg-3.col-sm-2').outerHeight());
          utils.equalHeights($('.view-taxonomy-term .col.col-lg-3.col-sm-2'));
          utils.equalHeights($('.field-type-aba-product-reference .field-item'));
        },10000);

  
  $('.view-taxonomy-term .col.col-lg-3.col-sm-2  .abaproduct-title a').each(function (index, element) {
    // element == this  
    var text = $(this).text();
    text = text.substr(0,55) + '...';
    $(this).text(text);
        
  });

        //checkout
        $('#uc-cart-checkout-form .next-button').on('click',(e)=>{
          e.preventDefault();

          let element = $(e.target);
          let index = element.closest('fieldset').index() + 1;
          element.closest('fieldset').find($('.fieldset-title span')).trigger('click');
          console.log(element.closest('fieldset').parent().find('fieldset').eq(index).find($('.fieldset-title span')), 'next');
          element.closest('fieldset').parent().find('fieldset').eq(index).find($('.fieldset-title span')).trigger('click');
          element.closest('fieldset').parent().find('fieldset').eq(index).find($('.fieldset-title')).trigger('click');
        });

        //browse search bar
        if ($('#browse-search').length) {
          $('#browse-search').off();
          $('#browse-search input').off();
          $('#browse-search input').on('submit click', (e)=>{
            
            e.preventDefault();

            if ($('#browse-search input.form-text').val() != "" ) {
              window.location.href =  window.location.protocol + "//" + window.location.hostname + "/search/site/" + $('#browse-search input.form-text').val();
            }

          })
        }
        //if banner, remove title
        if ($('.view-hero-banners').length) {
          $('.page-title').remove();
        }

        if ($('#block-uc-cart-cart').length) {
          let numberofItems = $('#block-uc-cart-cart .num-items').text();
          $('.cart-count').text(`(${numberofItems})`);
          $('.cart-count').parent().attr('href','/cart');
          $('#block-uc-cart-cart').remove();
        }

        //events
        if ($('.view-display-id-page_1').length) {
          $('.view-id-promo_tiles').appendTo('.view-display-id-page_1 > .view-content');
        }
        if ($('.node-event.event-detail').length) {
          $('#tab-0').text(' ');
          $('.field-name-body').appendTo('#tab-0');

          if ($('.field-name-field-products').length) {
            $('<li class="nav-item"><a class="nav-link" data-toggle="tab" href="#tab-1">Books</a></li>').appendTo('#myTab');
            $('<div class="tab-pane" role="tabpanel" id="tab-1"></div>').appendTo($('.tab-content'));
            $('.field-name-field-products').appendTo('#tab-1');
          }

          $('.field-name-field-date, .field-name-field-address').wrapAll('<div class="meta-container"></div>');

          $('.page-title').prependTo('.meta-container');

          let linkURL = $('.field-name-field-event-link-url .field-item').text();
          let linkText = $('.field-name-field-event-link-text .field-item').text();
          $('.field-name-field-event-link-url,.field-name-field-event-link-text').remove();

          $('<a class="btn btn-primary" href="'+linkURL+'">'+linkText+'</a>').appendTo('.meta-container');
          $('.sharethis-inline-share-buttons').appendTo('.meta-container');
        }

        //inline all image backgrounds
        //
        $('[data-image-background]').each(function (index, element) {
          // element == this
          // console.log($(element), $(element).data('image-background'), "thing");
          $(element).css('background-image',$(element).data('image-background')+'');
        });


        $('div.messages').appendTo( $('.alert-global') );
        // ordo.Services();
        $('body').addClass('loaded');


      } catch (err) {
        if (ordo.debug) {
          console.log(err);
        }

        $('body').addClass('loaded');
      }
    },
    Products: () =>{
      if ($('#block-system-main > .content > .abaproduct-content').length ||
      $('#block-system-main > .content > .node-product').length) {
        setTimeout(()=>{
          $('.add-to-cart').appendTo('.product-info.sell-price');
          $('.abaproduct-authors').prependTo('.abaproduct-page-details');
          
          $('.abaproduct-related-editions').appendTo('.abaproduct-page-details');
          $('.abaproduct-large-image form').appendTo('.abaproduct-page-details');
          
          $('<div class="tabs-nav" />').insertBefore($('.abaproduct-body').first());
          
          $('<ul class="nav nav-tabs" />').prependTo('.tabs-nav');
          let numTabs = 0;
          $('.abaproduct-body').each((i,x)=>{
            numTabs = numTabs + 1;
            $(x)
              .find('h3')
              .wrapAll('<li class="nav-item"/>')
              .wrapAll('<a data-toggle="tab" href="#tab-'+i+'" ></a>');
              // .appendTo('ul.nav-tabs.nav');
            console.log('this', $(x));
          });

          $('.nav-tabs a').first().addClass('active');
          
          for (let index = 0; index < numTabs; index++) {
            console.log($('.abaproduct-body').eq(index), 'thisa one');
            if (index == 0) {
              $('.abaproduct-body').eq(index).wrap('<div class="tab-pane fade active show" id="tab-'+index+'" ></div>');  
            }else{

              $('.abaproduct-body').eq(index).wrap('<div class="tab-pane fade" id="tab-'+index+'" ></div>');
            }
          }

          $('.nav-item').appendTo('ul.nav-tabs.nav');
          $('.tab-pane').wrapAll('<div class="tab-content" ></div>');

          $('.abaproduct-large-image').addClass('col-lg-4');
          $('.abaproduct-page-details').addClass('col-lg-4');
          $('<div class="col-lg-4"></div>').insertAfter($('.abaproduct-page-details'));
          $('.collapsible.abaproduct-related-editions').appendTo($('.abaproduct-content > .col-lg-4').last());
          // $('.abaproduct-authors, .abaproduct-price, .abaproduct-cart').appendTo($('.abaproduct-page-details col'));
          // $('.abaproduct-page-details').insertAfter('<div class="col col-4"></div>');
          // $('.abaproduct-page-details').siblings().detach();
          $('#aba-product-details-fieldset').appendTo($('.abaproduct-content > .col-lg-4').last());
          $('.page-title').prependTo('.abaproduct-page-details.col-lg-4');
          $('.nav-tabs a').first().addClass('active');
        },1000);
      }
    },
    Bindings: () =>{
      if (ordo.debug) {
        console.log('ordo.Bindings()');
      }
      //menu top nav
      $('#main-menu > ul >li>a,.responsive-menus-simple>ul>li>a').on('click',(e)=>{
        e.preventDefault();
        $(e.target).siblings('span').children().trigger('click');
      });
      //hamburger
      $('.hamburger-menu').off();
      $('.hamburger-menu').on('click', () =>{
        $('.bar').toggleClass('animate');
        $(ordo).toggleClass('active');
        $('body').toggleClass('in-menu');
        //cleanup
        if ($('.nav-drop.in-view').length) {
          $('.menu-item-has-children .in-view').removeClass('in-view');
          $('.mobile-menu .in').removeClass('in');
          return false;
        }
        $('.__js').toggleClass('is-visible');
        if ($('.__js').hasClass('is-visible')) {
          $('html').css('overflow', 'hidden');
        } else {
          $('html').css('overflow', 'auto');
        }
      });
      //mobile-menu-lite
      $('.js-submenu-trigger svg', '.mobile-menu').off();
      $('.js-submenu-trigger svg', '.mobile-menu .js-submenu-trigger').on(
        'click',
        (e) =>{
          e.preventDefault();
          $('.__js .in-view').removeClass('in-view');
        }
      );

      // '.sub-menu-action'
      //mobile menu sub items
      $('.sub-menu-action').off();
      $('.sub-menu-action').on('click', (e) =>{
        e.preventDefault();
        //cleanup
        if ($('.__js').hasClass('is-visible')) {
          $('.__js .in-view').removeClass('in-view');
        }

        var parentElem = $(ordo).parent();
        parentElem.siblings('.nav-drop').toggleClass('in-view');

        if (!$('.nav-drop.in-view')) {
          $(ordo).toggleClass('in');
        }

        $('.__js').toggleClass('is-visible');
      });

      $('.fas.fa-search').on('click', (e) =>{
        e.preventDefault();

        $('#preface-v1').toggleClass('open');
      });
      $(' .block-search .form-text').on('click', (e) =>{
        e.preventDefault();
        if (!$('#preface-v1').hasClass('open')) {
          
          $('#preface-v1').toggleClass('open');
        }
      });

      $('#search-block-form #edit-actions').on('click', (e) =>{
        e.preventDefault();

        $('#preface-v1').toggleClass('open');
      });


      $('#search-block-form #edit-actions #edit-submit, #search-block-form #edit-actions #edit-submit--2').on('click', (e) =>{
        e.preventDefault();

        $('#search-block-form').submit();
      });

      $('.search-form').on('submit', (e) =>{
        e.preventDefault();

        if ($('.active .query').val().length > 2) {
          var query = $('.active .query').val();

          $('.active .query').css('border', '1px solid green');
          $('.search-form.active input').data(
            'value',
            $('.search-form.active input[name="action"]').attr('value') + query
          );
          window.location = $('.search-form.active input').data('value');
        } else {
          $('.active .query').css('border', '1px solid red');
        }
      });

      /**ON RESIZE
       * if mobile nav is open, close it and cleanup
       */
      $(window)
        .on('resize', () =>{
          if (true) {
            // var distance = $('#nav-main').offset().top;
            var windowEl = $(window);
            var navEl = $('#nav-main');
            var utilEL = $('.utility-nav-container');
            var deskNavEl = $('.desktop-nav-container');
            var logoEl = $('.logo-container');

            windowEl.on('scroll touch', () =>{
              var triggerd = true;
              if (windowEl.scrollTop() < 35) {
                navEl
                  .addClass('hover-enabled')
                  .removeClass('alternate_header_enabled');
                deskNavEl.removeClass('transition-margin');
                logoEl.removeClass('transition-margin');
                // utilEL.removeClass('fadeOutDown');
                // utilEL.show();
              } else if (windowEl.scrollTop() >= 35) {
                if (triggerd) {
                  navEl
                    .removeClass('hover-enabled')
                    .addClass('alternate_header_enabled');
                  // utilEL.addClass('animated fadeOutDown');
                  // utilEL.hide();
                  deskNavEl.addClass('transition-margin');

                  logoEl.addClass('transition-margin');
                  triggerd = false;
                }
              }
            });
          }
        })
        .resize();

      /**
       * Selects animation
       */
      $(window)
        .on('scroll resize', () =>{
          ordo.Selects();
        })
        .trigger('resize');

      /*
       * GLOBAL SCROLL TO
       */
      $('a[href*="#"]')
        // Remove links that don't actually link to anything
        .not('[href="#"]')
        .not('[href="#0"]')
        .click((event) =>{
          // On-page links
          if (
           true
          ) {
            // Figure out element to scroll to
            let theHash = "#"+$(event.target).attr('href').split('#')[1];
            var target = $(theHash);
            console.log(target);
            target = target.length
              ? target
              : $('[name=' + ordo.hash.slice(1) + ']');
            // Does a scroll target exist?
            if (target.length) {
              // Only prevent default if animation is actually gonna happen
              event.preventDefault();
              $('html, body').animate(
                {
                  scrollTop: target.offset().top - 20
                },
                1000,
                () =>{
                  // Callback after animation
                  // Must change focus!
                  var $target = $(target);
                  $target.focus();
                  if ($target.is(':focus')) {
                    // Checking if the target was focused
                    return false;
                  } else {
                    $target.attr('tabindex', '-1'); // Adding tabindex for elements not focusable
                    $target.focus(); // Set focus again
                  }
                }
              );
            }
          }
        });

        //FAQ tabs
        $('.view-faq-page .view-content > .view-grouping').first().addClass('active');
        
        $('.view-faq-page .view-grouping-content').each((i,x)=>{
          let $child = $(x).find('.views-row');
          let calcHeight = 0;
          $child.each((i1,x1)=>{
            calcHeight = calcHeight+$(x1).outerHeight();
          });
          $(x).parent().css('height', calcHeight);
        });

        setTimeout(() => {
          let calcHeight = 0;
          $('.view-faq-page .view-grouping-header').each((i,x)=>{
            let $child = $(x);
            if ($(x).outerHeight() > calcHeight) {
              
              calcHeight = $(x).outerHeight();
            }
          
          });
          $('.view-faq-page .view-grouping-header').css('height', calcHeight);

          $('.view-faq-page .view-grouping-header').on('click',(e)=>{
            e.preventDefault();
            $('.view-faq-page .view-grouping.active').removeClass('active');
            $(e.target).parent().parent().addClass('active');
          });

          $('.view-faq-page .views-field-nothing h2').on('click',(e)=>{
            $(e.target).parent().find('.faq-sub-detail').toggleClass('active');
          });

          //further subgroup
          let termGroups = [];
          let subtermGroups = [];
          let subtermObjects = [];
          $('.term-desc').each((i,v)=>{
            if (subtermGroups.includes($(v).parent().data('subgroup'))) {
              
            }else{
              var keyed = $(v).parent().data('subgroup');
              var value = $(v).closest('.faq-tab').clone(true);
              var desc = $(v).text();
              subtermGroups.push($(v).parent().data('subgroup'));
              if (keyed == 'default'){keyed='General'};
              subtermObjects.push({[keyed]:value,desc});
              // console.log($(v).closest('.view-grouping-content').index().toString());
              if (termGroups[`${$(v).closest('.view-grouping-content').index() - 1}`]) {
                
              }else{
                termGroups[`${$(v).closest('.view-grouping-content').index() - 1}`] = [];

              }
              termGroups[`${$(v).closest('.view-grouping-content').index() - 1}`].push({[keyed]:value});

              // v.remove();
              console.log('subterm order',termGroups);
              $(v).closest('.faq-tab').remove();
            }
          });

          $('.term-desc').each((i2,v2)=>{
            if ($(v2).text() != "") {
              $(v2).closest('.faq-tab').empty().remove();
            }
          });
          // console.log('element',termGroups);
          for (let index = 0; index < termGroups.length; index++) {
            const element = termGroups[index];
            let myowniterate = 0;
            element.forEach(element3 => {
              
              var keys = Object.keys( element3 );
              keys.forEach((ele, i) => {
                  // console.log('keys', subtermObjects[index], index);
                  for (let index2 = 0; index2 < subtermObjects.length; index2++) {
                    const element2 = subtermObjects[index2];
                    if (element2[ele.toString()]) {
                      
                      // console.log(element2[ele.toString()][0], 'to prepend');
                      $(element2[ele.toString()][0]).prependTo($('.view-faq-page > .view-content > .view-grouping').eq(index).find('.view-grouping-content'));
                    }
                  }
                  $('<p>'+subtermObjects[myowniterate].desc+'</p>').prependTo($('.view-faq-page > .view-content > .view-grouping').eq(index).find('.view-grouping-content'));
                $('<h4>'+ele.toString()+'</h4>').prependTo($('.view-faq-page > .view-content > .view-grouping').eq(index).find('.view-grouping-content'));
                myowniterate = myowniterate + 1;

              });
            });
          }

          $('.faq-tab .term-desc').remove();
        }, 200);


        //dropdown selects
        
    },
    Common: {
      equalHeights: (ele) =>{
        if (ordo.debug) {
          console.log('ordo.Common.equalHeights();');
        }
        $.each(ele, (index, val) =>{
          if ($(ordo).height() > ordo.tallest)
            ordo.tallest = $(ordo).height();
          $(ordo).height(ordo.tallest);
        });
      }
    },
    fadeTurn: (ele) =>{
      $(ele).addClass('pulsing');
      return true;
    },
    HomeHero: () =>{
      if (ordo.debug) {
        console.log('ordo.HomeHero()');
      }
    },
    Services: () =>{
      if (ordo.debug) {
        console.log('ordo.Services()');
      }
    },
    selectState:'ini',
    Selects: () =>{
      if (ordo.debug) {
        console.log('ordo.Selects()');
      }

      $('#block-aba-product-browse-ababook-browse .item-list > select').on('change', (e)=>{
        window.location.href = window.location.protocol + "//" + window.location.hostname + $(e.target).val();
      });
      
      if ($(window).outerWidth() <= 720) {
                // console.log(ordo.selectState);
                if (ordo.selectState == 'ini') {
          
                  if ($('#block-aba-product-browse-ababook-browse').length) {
                    let tmpChar = "";
                    $('#block-aba-product-browse-ababook-browse .content ul li a').each((elem, index)=>{
                      if (tmpChar != "") {
                        if ($(index).text().charAt(0) != tmpChar) {
                          tmpChar = $(index).text().charAt(0);
                          $('<br><span>'+ tmpChar + '</span>').insertBefore(
                            index
                          );
                          $(index).prepend('<hr>')
                        }
                      }else{
                        
                        if ($(index).text().charAt(0) != tmpChar) {
                          tmpChar = $(index).text().charAt(0);
                          $('<span>'+ tmpChar + '</span>').insertBefore(
                            index
                          );
                          $(index).prepend('<hr>')
                        }
                      }
                    });
                  }
                  
                }
        ordo.selectState = 'mobile';
        let $list   =  $('#block-aba-product-browse-ababook-browse .item-list > ul'),
        $select = $('<select />');

        $list.children('li').each(function(index, ele) {
          console.log($(ele).find('a').attr('href'));
          $select.append($('<option />').attr('value', $(ele).find('a').attr('href')).html($(this).html()));
        });
        // $('#block-aba-product-browse-ababook-browse .item-list > select option >span').remove();

        $list.replaceWith($select);
      }else{
        // console.log(ordo.selectState);
        if (ordo.selectState == 'mobile' || ordo.selectState == 'ini') {
          
          if ($('#block-aba-product-browse-ababook-browse').length) {
            let tmpChar = "";
            $('#block-aba-product-browse-ababook-browse .content ul li a').each((elem, index)=>{
              if (tmpChar != "") {
                if ($(index).text().charAt(0) != tmpChar) {
                  tmpChar = $(index).text().charAt(0);
                  $('<br><span>'+ tmpChar + '</span>').insertBefore(
                    index
                  );
                  $(index).prepend('<hr>')
                }
              }else{
                
                if ($(index).text().charAt(0) != tmpChar) {
                  tmpChar = $(index).text().charAt(0);
                  $('<span>'+ tmpChar + '</span>').insertBefore(
                    index
                  );
                  $(index).prepend('<hr>')
                }
              }
            });
          }
          ordo.selectState = 'desktop';
        }

        let $list   =  $('#block-aba-product-browse-ababook-browse .item-list > select'),
        $select = $('<ul />');

        $list.children('option').each(function(index) {
          $select.append($('<li />').attr('value', index).html($(this).html()));
        });
        

        $list.replaceWith($select);
      }

    },
    Count: () =>{
      if (ordo.debug) {
        console.log('ordo.Count()');
      }
    },
    Search: () =>{
      if (ordo.debug) {
        console.log('ordo.Search()');
      }
    },
    CheckTouch: () =>{
      if (ordo.debug) {
        console.log('ordo.Grid');
      }
    },
    GridSelect: () =>{
      if (ordo.debug) {
        console.log('ordo.Grid-Select');
      }
    }
  };
  //are we good?
  if ($('body').length) {
    console.log('here')
    ordo.init();
  }
};
